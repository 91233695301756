import * as OriginalSentry from '@sentry/react';
import { ENV, releaseVersion } from 'config';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const Sentry = OriginalSentry;
export default Sentry;

const environmentMap = {
  DEV: 'development',
  STG: 'staging',
  TEST: 'test',
  PROD: 'production',
};

const debug = ENV === 'DEV';

export const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://88dd26e6c272cf335cd51abad0714724@o4507069392551936.ingest.de.sentry.io/4507101204316240',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    release: releaseVersion,
    environment: environmentMap[ENV] || environmentMap.DEV,
    debug,
    // Performance Monitoring
    tracesSampleRate: 0.01,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'stg-1.avitest.info/api', 'api.avimedical.com/api'],
    normalizeDepth: 10,
    // Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1,
  });
};
